<template>
  <div>
    <banner
      title="Informasi Pembinaan Terbaru"
      description="Salah satu upaya yang dilakukan oleh Pusat Perbukuan untuk mewujudkan buku bermutu adalah melalui program-program pembinaan pelaku perbukuan dalam hal pembinaan kompetensi dan manajemen perbukuan. Berikut adalah informasi mengenai program-program yang akan berlangsung maupun yang sudah terlaksana sebelumnya."
      :breadcrumbs="breadcrumbs"
      :icon="require('@/assets/home/decoration/penilaian.svg')"
    />
    <section id="content" class="mt-4">
      <div class="container">
        <div class="row">
          <template v-if="$store.state.loadPage">
            <div v-for="i in 4" :key="i" class="col-lg-3">
              <div class="card shadow">
                <div class="card-body">
                  <vue-skeleton-loader
                    class="rounded"
                    type="rect"
                    :width="210"
                    :height="200"
                    animation="wave"
                  />
                  <vue-skeleton-loader
                    class="mt-3"
                    type="rect"
                    :width="210"
                    :height="20"
                    animation="wave"
                  />
                  <div class="row">
                    <div class="col-md-6">
                      <vue-skeleton-loader
                        class="mt-3"
                        type="rect"
                        :width="100"
                        :height="15"
                        animation="wave"
                      />
                    </div>
                    <div class="col-md-6">
                      <vue-skeleton-loader
                        class="mt-3"
                        type="rect"
                        :width="87"
                        :height="15"
                        animation="wave"
                      />
                    </div>
                    <div class="col-md-6">
                      <vue-skeleton-loader
                        class="mt-3"
                        type="rect"
                        :width="99"
                        :height="10"
                        animation="wave"
                      />
                    </div>
                    <div class="col-md-6">
                      <vue-skeleton-loader
                        class="mt-3"
                        type="rect"
                        :width="87"
                        :height="10"
                        animation="wave"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            class="col-12 col-lg-4"
            v-for="(training, index) in trainings.filter(training => training.status != 'draft')"
            :key="index"
          >
            <PembinaanCard :training="training" class="mb-4" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <nav class="courses-pagination mt-50">
              <div class="row my-4 text-center">
                <div class="col-md-12">
                  <nav id="paging"></nav>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";
import PembinaanCard from "@/components/pembinaan/Card.vue";
import Banner from "@/components/homepage/Banner.vue";
export default {
  data() {
    return {
      breadcrumbs: [
        {
          label: "Beranda",
          url: "/",
        },
        {
          label: "Pembinaan",
          url: "",
        },
      ],
    };
  },
  components: {
    VueSkeletonLoader,
    PembinaanCard,
    Banner,
  },
  computed: {
    ...mapState(["trainings"]),
  },
  methods: {
    ...mapActions(["fetchAllTraining"]),
  },

  created() {
    this.fetchAllTraining();
  },
};
</script>
