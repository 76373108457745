<script>
import moment from "moment";
import SvgIcon from "../common/SvgIcon.vue";
moment.locale("id");
export default {
  components: { SvgIcon },
  name: "PembinaanCard",
  props: {
    training: Object,
  },
  computed: {
    isStatusOpen() {
      return this.training?.status === "publish" ? true : false;
    },
  },
  methods: {
    convertDate(date) {
      return moment(date).format("LL");
    },
  },
};
</script>
<template>
  <div class="training-card">
    <div class="position-relative overflow-hidden rounded-lg">
      <img :src="training.cover" alt="" />
      <div
        class="training-card--image-overlay bg-grey-700/70"
        v-if="!isStatusOpen"
      ></div>
    </div>
    <div class="training-card__body">
      <div class="d-flex align-items-center">
        <span
          class="training-card__badge"
          :class="{ 'training-card__badge--closed': !isStatusOpen }"
        >
          Pendaftaran {{ isStatusOpen ? "Dibuka" : "Ditutup" }}
        </span>
        <span class="pl-3 text-sm text-muted d-flex align-items-center">
          <svg-icon name="user-three" style="width:1.5rem;" />
          <span class="pl-2">
            {{
              training.seats - training.registered > 0
                ? training.seats - training.registered
                : 0
            }}
            Kuota tersisa
          </span>
        </span>
      </div>
      <p class="h5 font-weight-bold training-card--title">
        {{ training.title }}
      </p>
      <p class="text-muted" :class="{ invisible: !training.subtitle }">
        {{ training.subtitle || "-" }}
      </p>
      <div class="training-card__info">
        <div class="d-flex align-items-center">
          <svg-icon name="calendar-check" class="training-card__info-icon" />
          <div>
            <div class="text-nowrap">{{ convertDate(training.start) }},</div>
            <div class="font-weight-bold">
              {{ training.start.substr(10, 6) }} -
              {{ training.end.substr(10, 6) }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <svg-icon name="map-pin" class="training-card__info-icon" />

          <div class="text-grey-500">
            {{ training.location || "Belum ditentukan" }}
          </div>
        </div>
      </div>

      <router-link
        :is="isStatusOpen ? 'router-link' : 'button'"
        :to="{
          path: '/pelaku/pembinaan/detail',
          query: { id: training.id },
        }"
        class="btn btn-block "
        :class="isStatusOpen ? 'btn-secondary' : 'btn-disabled'"
      >
        <span>Lihat Selengkapnya</span>
      </router-link>
    </div>
  </div>
</template>
<style scoped lang="scss">
.btn-disabled,
.btn-disabled:hover {
  background: var(--grey-500);
  color: white;
  cursor: not-allowed;
}
.training-card {
  border-radius: 0.75rem;
  padding: 0.5rem;
  border: 1px solid #f2f2f2;

  & img {
    width: 100%;
    border-radius: 0.5rem;
    transition: all 0.3s;
    &:hover {
      scale: 1.1;
    }
  }

  &--image-overlay {
    position: absolute;
    border-radius: 0.5rem;
    inset: 0;
    pointer-events: none;
  }

  &--title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__badge {
    padding: 0.25rem 1rem;
    font-size: 12px;
    border-radius: 2rem;
    color: #219653;
    background: #eafff3;
    font-weight: 600;
    &--closed {
      background: #ffe3e3;
      color: #eb5757;
    }
  }
  &__body {
    & > * {
      margin-top: 0.75rem;
    }
  }

  &__info {
    background: var(--grey-100);
    padding: 0.5rem 4px;
    display: flex;
    justify-content: center;
    gap: 1rem;
    &-icon {
      height: 1.75rem;
      width: 1.5rem !important;
      & + div {
        flex-grow: 1;
        padding-left: 4px;
        font-size: 12px;
      }
    }
  }
}
</style>
